<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="NIS">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudent.nis"
            @change="(e) => handleChange(e.target.value, 'nis')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="NISN">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudent.nisn"
            @change="(e) => handleChange(e.target.value, 'nisn')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Full Name">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudent.nama"
            @change="(e) => handleChange(e.target.value, 'nama')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="E-mail">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudent.email"
            @change="(e) => handleChange(e.target.value, 'email')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Gender">
          <a-radio-group
            class="w-100"
            size="large"
            default-value="Laki-Laki"
            button-style="solid"
            :value="dataStudent.jenis_kelamin"
            @change="(e) => handleChange(e.target.value, 'jenis_kelamin')"
          >
            <a-radio-button class="w-50 text-center" value="Laki-Laki">
              Laki-Laki
            </a-radio-button>
            <a-radio-button class="w-50 text-center" value="Perempuan">
              Perempuan
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Religion">
          <a-select
            size="large"
            class="w-100"
            :value="dataStudent.agama"
            @change="(e) => handleChange(e, 'agama')"
          >
            <a-select-option
              v-for="(item, idx) in agama"
              :key="idx"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Address">
          <a-textarea
            class="w-100"
            :value="dataStudent.alamat"
            @change="(e) => handleChange(e.target.value, 'alamat')"
            :rows="4"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    dataStudent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      agama: ['Islam', 'Katholik', 'Kristen Protestan', 'Hindu', 'Budha', 'Other'],
    }
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>
</style>
